import React from "react";
import {
  Route,
  Switch,
  Redirect,
  withRouter,
} from "react-router-dom";
import classnames from "classnames";

// styles
import useStyles from "./styles";

// components
import Header from "../Header";
import Sidebar from "../Sidebar";

// pages
import Dashboard from "../../pages/dashboard";

// context
import {useLayoutState} from "../../context/LayoutContext";
import OrdersContainer from "../../pages/orders/OrdersContainer";
import EmployeeContainer from "../../pages/employee/EmployeeContainer";
import ReportContainer from "../../pages/report/ReportContainer";
import GoalsContainer from "../../pages/goals/GoalsContainer";
import RidersManagement from "../../pages/availableRiders/ridersManagement";
import AgentManagement from "../../pages/agentManagement/subAgentList";
import UpdateAgent from "../../pages/agentManagement/updateSubAgent";
import {authClass} from "../../utils/auth.util";
import NotFound from "../../pages/error/NotFound";

function Layout(props) {
  var classes = useStyles();

  var layoutState = useLayoutState();

  return (
    <div className={classes.root} >
      <>
        <Header history={props.history} />
        <Sidebar {...props} />
        <div
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened,
          })}
          style={{overflowY: 'hidden'}}
        >
          <div className={classes.fakeToolbar} />
          <Switch>
            <PrivateRoute path="/app" exact component={OrdersContainer} />
            <PrivateRoute path="/app/dashboard" component={Dashboard} />
            <PrivateRoute path="/app/orders" exact component={OrdersContainer} />
            {/* <PrivateRoute path="/app/orders2" exact component={OrdersContainer} /> */}
            {/* ORDER SUB ROUTE, THIS WILL NOT SHOW IN DRAWER BUT WORK ON CLICK */}
            <PrivateRoute path="/app/orders/:section_type" exact component={OrdersContainer} />
            {/* END ORDER SUB ROUTE, THIS WILL NOT SHOW IN DRAWER BUT WORK ON CLICK */}

            <PrivateRoute path="/app/employee" component={EmployeeContainer} />
            <PrivateRoute path="/app/report" component={ReportContainer} />
            <PrivateRoute path="/app/goals" component={GoalsContainer} />
            <PrivateRoute path="/app/riders" component={RidersManagement} />
            <PrivateRoute path="/app/agents" component={AgentManagement} />
            <PrivateRoute path="/app/getByid/:id" component={UpdateAgent} />
            <PrivateRoute path="/app/" component={NotFound} />
          </Switch>

        </div>
      </>
    </div>
  );
}
function PrivateRoute({component, ...rest}) {
  return (
    <Route
      {...rest}
      render={(props) =>
        authClass.getUser?.token ? (
          React.createElement(component, props)
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: {
                from: props.location,
              },
            }}
          />
        )
      }
    />
  );
}
export default withRouter(Layout);
