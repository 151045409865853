import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import {
  Grid,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
} from "@material-ui/core";
import useStyles from "./styles";
import apiServices from "../../services/requestHandler";
import { toast } from "react-toastify";
import { withStore } from "../../utils/store.util";
import moment from "moment";
import localStoreUtil from "../../utils/localstore.util";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import StoreOutlinedIcon from "@material-ui/icons/StoreOutlined";
import "./styles.css";

function Login() {
  var classes = useStyles();
  let [agentDetail, setAgentDetail] = useState();
  let [agentID, setAgentID] = useState();

  let [storeID, setStoreID] = useState("all");
  let [storeName, setStoreName] = useState();

  let [availRiders, setAvailableRiders] = useState(0);

  let [updatedTime, setUpdatedTime] = useState();
  let [lastUpdatedBy, setLastUpdatedBy] = useState();

  useEffect(() => {
    const checkSetInatailState = async () => {
      const getAgentData = await localStoreUtil.get_data("agent");

      setAgentDetail(getAgentData);
      setAgentID(getAgentData?._id);

      if (getAgentData?.supervisorStores?.length === 1) {
        setAvailableRiders(
          getAgentData?.supervisorStores?.[0]?.storeID?.availableRiders
        );
        setStoreID(getAgentData?.supervisorStores?.[0]?.storeID?._id);
        setStoreName(
          getAgentData?.supervisorStores?.[0]?.storeID?.storeName?.en
        );
        setUpdatedTime(
          moment(
            getAgentData?.supervisorStores?.[0]?.storeID?.updatedAt
          ).format("DD.MMM.YYYY HH:mm")
        );
        setLastUpdatedBy(
          getAgentData?.supervisorStores?.[0]?.storeID?.updatedBy?.name
        );
      }
    };

    checkSetInatailState();
  }, []);

  const handleRidersChange = (event) => {
    setAvailableRiders(event.target.value);
  };

  const handleStoreSelect = (event) => {
    setStoreID(event.target.value);

    var availRiders = agentDetail?.supervisorStores.filter(function (
      _responseStore
    ) {
      return _responseStore?.storeID?._id === event.target.value;
    });

    setStoreName(availRiders?.[0]?.storeID?.storeName?.en);
    setAvailableRiders(availRiders?.[0]?.storeID?.availableRiders);

    setUpdatedTime(
      moment(availRiders?.[0]?.storeID?.updatedAt).format("DD.MMM.YYYY HH:mm")
    );
    setLastUpdatedBy(availRiders?.[0]?.storeID?.updatedBy?.name);
  };

  const updateAvailableRiders = async (e) => {
    e.preventDefault();

    if (storeID === "all") {
      toast.error("Please Select Store");
    } else if (!availRiders || availRiders === "0") {
      toast.error("Available Riders Must Be Greater Than 0");
    } else if (availRiders === "E") {
      toast.error("Available Riders Must Be Number");
    } else {
      let data = {
        _id: storeID,
        availableRiders: availRiders,
        updatedBy: agentID,
      };

      const response = await apiServices.updateStoreRiders(data);

      if (response.data.status === 200) {
        let stat = agentDetail?.supervisorStores?.map((obj) => {
          if (`${obj?.storeID?._id}` === `${storeID}`) {
            obj = {
              ...obj,
              storeID: {
                ...obj.storeID,
                availableRiders: response.data.data.availableRiders,
                updatedBy: response.data.data.updatedBy,
              },
            };
            return obj;
          }

          return obj;
        });

        delete agentDetail.supervisorStores;

        let newObj = { ...agentDetail, supervisorStores: stat };

        setAgentDetail(newObj);

        setUpdatedTime(
          moment(response.data.data.updatedAt).format("DD.MMM.YYYY HH:mm")
        );

        setLastUpdatedBy(response.data.data?.updatedBy?.name);

        toast.success(
          "Available Riders Updated In " + response.data.data?.storeName?.en
        );
      } else {
        toast.error(response.data.message);
      }
    }
  };

  return (
    <>
      <Grid container className={"background"}>
        <Grid md={2} item>
          {" "}
        </Grid>
        <Grid md={8} item style={{ marginTop: "100px" }}>
          <Grid className={"gray-background-containers"}>
            <Grid
              md={12}
              item
              style={{
                paddingLeft: 20,
                paddingTop: 10,
                marginBottom: 15,
              }}
            >
              <p
                style={{
                  color: "var(--dominos-blue)",
                  fontSize: "22px",
                  fontWeight: "bold",
                  textAlign: "center",
                  fontVariant: "all-petite-caps",
                }}
              >
                UPDATE RIDERS AVAILABILITY{" "}
                {storeID === "all" ? "" : "IN " + storeName}
              </p>
            </Grid>

            <Grid
              container
              spacing={2}
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "20px 36px",
              }}
            >
              <Grid>
                <Grid
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Grid md={6}>
                    <Grid
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <StoreOutlinedIcon
                        style={{
                          color: "var(--dominos-blue)",
                          fontSize: "34px",
                        }}
                      />

                      <p
                        style={{
                          color: "var(--dominos-blue)",
                          fontSize: "19px",
                          fontWeight: "bold",
                          textAlign: "center",
                          marginLeft: "10px",
                          marginTop: "0px",
                          marginBottom: "0px",
                        }}
                      >
                        STORE NAME
                      </p>
                    </Grid>
                  </Grid>

                  <Grid md={6} style={{ marginBottom: "10px" }}>
                    {agentDetail?.supervisorStores?.length === 1 ? (
                      <TextField
                        id="outlined-basics"
                        variant="outlined"
                        fullWidth
                        autoComplete="off"
                        size="small"
                        className={classes.textBackground}
                        inputProps={{ readOnly: true }}
                        value={storeName}
                      />
                    ) : (
                      <FormControl variant="outlined" fullWidth>
                        <Select
                          fullWidth
                          inputProps={{
                            autocomplete: "new-password",
                            form: {
                              autocomplete: "off",
                            },
                          }}
                          labelId="demo-simple-select-filled-label"
                          id="demo-simple-select-filled"
                          onChange={(event) => handleStoreSelect(event)}
                          name="store"
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            getContentAnchorEl: null,
                          }}
                          value={storeID}
                        >
                          <MenuItem value={"all"} disabled={true}>
                            Select Store
                          </MenuItem>

                          {(agentDetail?.supervisorStores || []).map(
                            (store, index) => (
                              <MenuItem value={store?.storeID?._id}>
                                {(store?.storeID?.storeName || {}).en}
                              </MenuItem>
                            )
                          )}
                        </Select>
                      </FormControl>
                    )}
                  </Grid>
                </Grid>
              </Grid>

              <Grid style={{ marginTop: "15px" }}>
                <Grid
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Grid md={6}>
                    <Grid
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <PersonOutlineOutlinedIcon
                        style={{
                          color: "var(--dominos-blue)",
                          fontSize: "34px",
                        }}
                      />

                      <p
                        style={{
                          color: "var(--dominos-blue)",
                          fontSize: "19px",
                          fontWeight: "bold",
                          textAlign: "center",
                          marginLeft: "10px",
                          marginTop: "0px",
                          marginBottom: "0px",
                        }}
                      >
                        AVAILABLE RIDERS
                      </p>
                    </Grid>
                  </Grid>

                  <Grid md={6} style={{ marginBottom: "10px" }}>
                    <TextField
                      type={"number"}
                      id="outlined-basics"
                      variant="outlined"
                      fullWidth
                      autoComplete="off"
                      size="small"
                      className={classes.textBackground}
                      value={availRiders}
                      onChange={(event) => handleRidersChange(event)}
                      name={"availRiders"}
                      onKeyPress={(e) => {
                        if (
                          e.charCode === 45 ||
                          e.charCode === 43 ||
                          e.charCode === 101
                        ) {
                          e.preventDefault();
                        }
                      }}
                      InputProps={{
                        inputProps: {
                          min: 1,
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid style={{ marginTop: "15px" }}>
                <Grid
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Grid md={6}>
                    <Grid
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <PersonOutlineOutlinedIcon
                        style={{
                          color: "var(--dominos-blue)",
                          fontSize: "34px",
                        }}
                      />

                      <p
                        style={{
                          color: "var(--dominos-blue)",
                          fontSize: "19px",
                          fontWeight: "bold",
                          textAlign: "center",
                          marginLeft: "10px",
                          marginTop: "0px",
                          marginBottom: "0px",
                        }}
                      >
                        TOTAL RIDERS
                      </p>
                    </Grid>
                  </Grid>
                  <Grid md={6} style={{ marginBottom: "10px" }}>
                    <TextField
                      id="disabled-outlined-basics"
                      variant="outlined"
                      fullWidth
                      autoComplete="off"
                      size="small"
                      className={classes.textBackground}
                      disabled={true}
                      // inputProps={{ readOnly: true }}
                      // value={totalRiders}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid style={{ marginTop: "15px" }} className={"updatedBy"}>
                <Grid
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Grid md={6}>
                    <p
                      style={{
                        color: "var(--dominos-blue)",
                        fontSize: "14px",
                        fontWeight: "bold",
                      }}
                    >
                      Last Updated by: {lastUpdatedBy ? lastUpdatedBy : "-"}
                    </p>
                    <p
                      style={{
                        color: "var(--dominos-blue)",
                        fontSize: "14px",
                        fontWeight: "bold",
                      }}
                    >
                      Last Updated at: {updatedTime ? updatedTime : "-"}
                    </p>
                  </Grid>
                  <Grid
                    md={6}
                    style={{ marginBottom: "10px" }}
                    className={"btnRider"}
                  >
                    <Button
                      type={"button"}
                      className={"btn-red"}
                      variant={"contained"}
                      onClick={(e) => {
                        updateAvailableRiders(e);
                      }}
                    >
                      UPDATE RIDERS STATUS
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid md={2} item>
            {" "}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default withStore(withRouter(Login));
