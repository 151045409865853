import React from "react";
import { withRouter } from "react-router-dom";
import { Button, FormHelperText } from "@material-ui/core";
import useStyles from "./styles";
import img from "./bg-1.jfif";
import logo from "./login.png";
import userInput from "./mobile.png";
import apiServices from "../../services/requestHandler";
import FlagInput from "../../components/ReUseableComponant/FlagistInput/FlagInput";
import { toast } from "react-toastify";

function ForgetPassword(props) {
  var classes = useStyles();
  const [code, setCode] = React.useState("+994");
  const [mobileNum, setMobileNum] = React.useState();
  const [openFlagList, setopenFlagList] = React.useState(false);

  const onChangeHandler1 = (e) => {
    setMobileNum(e.target.value);
  };

  var mobile = "";

  const codeSend = async () => {
    if (code.slice(0, 1) === "+" || code.slice(0, 1) === "0") {
      mobile = code.replace("+", "");
    } else {
      mobile = code.replace("+", "");
    }

    let data = {
      countryCode: mobile,
      mobile: mobileNum,
    };

    const response = await apiServices.forgetPassword(data);
    if (response.data.status === 200) {
      toast.success(response.data.data);
      props.history.push("/login");

    } else {
      toast.error(response.data.message);
    }
  };

  const stateToggleHandler = async (name) => {
    try {
      await setopenFlagList(!openFlagList);
    } catch (error) {
      console.log(error);
    }
  };

  const customerFormOnChangeHandler = async (state, value) => {
    try {
      await setCode(state);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className={classes.overlay}>
        <img src={img} alt="background" className={classes.loginBackImg} />
        <div className={classes.logoLogin}>
          <div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img src={logo} alt="Logo" className={classes.logoSize} />
            </div>

            <h1
              style={{
                color: "white",
                marginTop: "30px",
                marginBottom: "15px",
              }}
            >
              ENTER YOUR REGISTERED MOBILE NUMBER
            </h1>

            <div style={{ width: "70%", marginLeft: "70px" }}>
              <form
                method="POST"
                onSubmit={(e) => {
                  e.preventDefault();
                  codeSend(e)
                }}
              >
                <div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <img
                      src={userInput}
                      alt="inputUser"
                      className="input-fields-logo"
                    />
                    <FlagInput
                      handleChangeMobile={(event) => onChangeHandler1(event)}
                      value={mobileNum}
                      handleOpenList={() => stateToggleHandler()}
                      openFlagList={openFlagList}
                      handleChangeMobileCode={(event) =>
                        customerFormOnChangeHandler(event)
                      }
                      customerFormOnChangeHandler={() =>
                        customerFormOnChangeHandler()
                      }
                      countryCode={setCode}
                      stateToggleHandler={() => stateToggleHandler()}
                    />
                  </div>
                </div>

                <div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      style={{
                        backgroundColor: "#0078AC",
                        fontFamily: "Bold",
                        fontSize: "20px",
                        color: "white",
                        marginTop: "24px",
                        marginLeft: "25px",
                        width: "50%",
                      }}
                      onClick={() => codeSend()}
                      className={"btn-bg-container"}
                      variant={"contained"}
                    >
                      SEND CODE
                    </Button>
                  </div>
                  <div>
                    <FormHelperText
                      id="standard-weight-helper-text"
                      className={classes.forgotPasswordLabel}
                      style={{
                        marginLeft: "28px",
                        marginTop: "24px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        props.history.push("/login");
                      }}
                    >
                      Back to Login
                    </FormHelperText>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default withRouter(ForgetPassword);
