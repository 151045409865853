import { get, post, del } from "./HttpProvider";
import featureConstants from "./features-constants";

const SERVICE_URLS = {
  // service URL's (API End-Points)
  restLogin: "agent/update-password",
  login: "agent/login",
  user: "users/all",
  getUserGeoCode : "/users/get-user-geocode",
  logout: "/agent/logout/agent/",
  menuType: "menu/getbytype/",
  crust: "/dough/get",
  getUser: "users/find-by-mobile",
  allStore: "/stores/public",
  storeLocation: "stores/location",
  addUser: "/users/create-user-by-agent",
  makeYourOwnPiza: "/menu/custom",
  cancelOrder: "orders/cancel-order",
  cancelOrderByIdInternee: "internee/cancel-order",
  orderPlace: "orders/agent-add",
  orderPlaceByInternee: "internee/place-order",
  getOrderById: "orders/set/reorder/callcenter/",
  addAddress: "address/add-by-agent/",
  updateAddress: "/address/update-by-agent/",
  getCoupons: "deals/agent/active",
  getCouponByID: "deals/get-callcenter/",
  getTax: "orders/price",
  makeDealFromProduct: "deals/make-deal",
  getPrice: "orders/price",
  forgetPassword: "agent/forgot-password",
  getCategories: "menu/categories",
  getProductCategories: "deals/search-by-product",
  getServiceMethodDeals: "deals/search-by-serviceMethod",
  getDealsSearchByCode: "deals/search-by-code",
  getDealsSearchCart: "deals/filtered-coupons",
  modifyOrder: "/agent/update-order",
  modifyOrderByInternee: "internee/modify-order",
  getAllowedItems: "/deals/get-allowedItms/",
  supervisorStore: "/store-supervisor",
  loginSupervisor: "/store-supervisor/login-supervisor",
  getEstimateTime: "orders/estimated-time",
  deleteAddress: "address/",
  agentUpdate: "/agent/update",
  sendSMSLocation: "/agent/user/send-location-sms",
  profileUpdateByAgent: "/users/update-user-by-agent",
  updateAgent: "agent/update",
  updateStoreRiders: "/stores/update",
  getSupervisorsAgents: "agent/supervisor/",
  getAgentById: "/agent/",
  getAgent: "agent/",
  agentForceLogout: "/agent/logout/agent/",
};

const agentForceLogout = (data) =>
  get(SERVICE_URLS.agentForceLogout, { data }, {});

const getAgent = () =>
  get(
    SERVICE_URLS.getAgent,
    {},
    {
      feature: featureConstants.blank,
    }
  );

const getAgentById = (data) =>
  get(
    SERVICE_URLS.getAgentById,
    { data },
    {
      feature: featureConstants.blank,
    }
  );

const getSupervisorsAgents = (data) =>
  get(
    SERVICE_URLS.getSupervisorsAgents,
    { data },
    {
      feature: featureConstants.blank,
    }
  );
  
const updateAgent = (data) =>
  post(SERVICE_URLS.updateAgent, data, {
    feature: featureConstants.blank,
  });
  const getUserGeoCode = (data) =>
  post(SERVICE_URLS.getUserGeoCode, data, {
    feature: featureConstants.blank,
  });
const profileUpdateByAgent = (data) => {
  return post(SERVICE_URLS.profileUpdateByAgent, data, {
    feature: featureConstants.blank,
  });
};

const deleteAddress = (data) =>
  del(
    `${SERVICE_URLS.deleteAddress}${data}`,
    {},
    {
      feature: featureConstants.blank,
    }
  );

const updateStoreRiders = (data) => {
  return post(SERVICE_URLS.updateStoreRiders, data, {
    feature: featureConstants.blank,
  });
};
const sendSMSLocation = (data) => {
  return post(SERVICE_URLS.sendSMSLocation, data, {
    feature: featureConstants.blank,
  });
};
const supervisorStore = (data) => {
  return post(SERVICE_URLS.supervisorStore, data, {
    feature: featureConstants.blank,
  });
};
const getEstimateTime = (data) => {
  return post(SERVICE_URLS.getEstimateTime, data, {
    feature: featureConstants.blank,
  });
};
const loginSupervisor = (data) =>
  post(SERVICE_URLS.loginSupervisor, data, { feature: featureConstants.login });
const login = (data) =>
  post(SERVICE_URLS.login, data, { feature: featureConstants.login });
const restLogin = (data) =>
  post(SERVICE_URLS.restLogin, data, { feature: featureConstants.blank });
const forgetPassword = (data) =>
  post(SERVICE_URLS.forgetPassword, data, { feature: featureConstants.blank });
//get user by phone
const getUserByPhone = (data) => {
  return post(SERVICE_URLS.getUser, data, { feature: featureConstants.blank });
};
const getUser = () => {
  return get(SERVICE_URLS.user, { feature: featureConstants.user });
};
const addCustomer = (data) => {
  return post(SERVICE_URLS.addUser, data, { feature: featureConstants.blank });
};

const logoutApi = (data) => {
  return get(SERVICE_URLS.logout, { data }, {});
};
const getMenuByType = (type) => {
  return get(SERVICE_URLS.menuType + "callcenter/" + type, {
    feature: featureConstants.menuType,
  });
};
const getCrust = () => {
  return get(SERVICE_URLS.crust, { feature: featureConstants.crust });
};
const getMakeYourOwnPiza = () => {
  return get(SERVICE_URLS.makeYourOwnPiza, { feature: featureConstants.blank });
};
//Get store by location lan lat
const getStoreByLocation = (lng, lat) => {
  return get(`${SERVICE_URLS.storeLocation}/${lng}/${lat}`, {
    feature: featureConstants.blank,
  });
};
//All Store
const getAllStores = (lng, lat) => {
  return get(`${SERVICE_URLS.allStore}`, { feature: featureConstants.blank });
};
//Cancel Order
const cancelOrderById = (data) => {
  return post(SERVICE_URLS.cancelOrder, data, {
    feature: featureConstants.blank,
  });
};

//Cancel Order
const cancelOrderByIdInternee = (data) => {
  return post(SERVICE_URLS.cancelOrderByIdInternee, data, {
    feature: featureConstants.blank,
  });
};
const getOrderById = (data) => {
  return get(`${SERVICE_URLS.getOrderById}`, data, {
    feature: featureConstants.blank,
  });
};
// get Deals
const getAllCoupons = () => {
  return get(
    `${SERVICE_URLS.getCoupons}`,
    {},
    {
      feature: featureConstants.blank,
    }
  );
};
const getCouponByID = (data) => {
  return get(
    `${SERVICE_URLS.getCouponByID}${data}`,
    {},
    {
      feature: featureConstants.blank,
    }
  );
};
//Order Place
const orderPlace = (data) => {
  return post(SERVICE_URLS.orderPlace, data, {
    feature: featureConstants.blank,
  });
};

//Place Internee Order
const orderPlaceByInternee = (data) => {
  return post(SERVICE_URLS.orderPlaceByInternee, data, {
    feature: featureConstants.blank,
  });
};
//Add Address
const addAddress = (id, data) => {
  return post(`${SERVICE_URLS.addAddress + id}`, data, {
    feature: featureConstants.blank,
  });
};
//Update Address
const updateAddress = (id, data) => {
  return post(`${SERVICE_URLS.updateAddress + id}`, data, {
    feature: featureConstants.blank,
  });
};
// get tax | Price Api
const getTax = (data) => {
  return post(`${SERVICE_URLS.getTax}`, data, {
    feature: featureConstants.blank,
  });
};
// make deal from cart items

const makeDealFromProduct = (data) => {
  return post(`${SERVICE_URLS.makeDealFromProduct}`, data, {
    feature: featureConstants.blank,
  });
};
const getPrice = (data) => {
  return post(`${SERVICE_URLS.getPrice}`, data, {
    feature: featureConstants.blank,
  });
};
const getServiceMethodDeals = (data) => {
  return post(`${SERVICE_URLS.getServiceMethodDeals}`, data, {
    feature: featureConstants.blank,
  });
};
const getCategories = () => {
  return get(
    `${SERVICE_URLS.getCategories}`,
    {},
    {
      feature: featureConstants.blank,
    }
  );
};
const getAllowedItems = (id) => {
  return get(
    `${SERVICE_URLS.getAllowedItems}`,
    { id },
    {
      feature: featureConstants.blank,
    }
  );
};
const getProductCategories = (data) => {
  return post(`${SERVICE_URLS.getProductCategories}`, data, {
    feature: featureConstants.blank,
  });
};

const getDealsSearchByCode = (data) => {
  return post(`${SERVICE_URLS.getDealsSearchByCode}`, data, {
    feature: featureConstants.blank,
  });
};

const getDealsSearchCart = (data) => {
  return post(`${SERVICE_URLS.getDealsSearchCart}`, data, {
    feature: featureConstants.blank,
  });
};

const modifyOrder = (data) => {
  return post(`${SERVICE_URLS.modifyOrder}`, data, {
    feature: featureConstants.blank,
  });
};

const modifyOrderByInternee = (data) => {
  return post(`${SERVICE_URLS.modifyOrderByInternee}`, data, {
    feature: featureConstants.blank,
  });
};

const agentUpdate = (data) => {
  return post(`${SERVICE_URLS.agentUpdate}`, data, {
    feature: featureConstants.blank,
  });
};

const apiServices = {
  // define variables
  updateAgent,
  sendSMSLocation,
  restLogin,
  forgetPassword,
  login,
  getUser,
  addCustomer,
  logoutApi,
  getCrust,
  getMenuByType,
  getUserByPhone,
  getStoreByLocation,
  getPrice,
  getAllStores,
  getMakeYourOwnPiza,
  cancelOrderById,
  cancelOrderByIdInternee,
  orderPlace,
  orderPlaceByInternee,
  getOrderById,
  addAddress,
  updateAddress,
  getAllCoupons,
  getUserGeoCode,
  getCouponByID,
  getTax,
  makeDealFromProduct,
  getServiceMethodDeals,
  getCategories,
  getProductCategories,
  getDealsSearchByCode,
  getDealsSearchCart,
  modifyOrder,
  modifyOrderByInternee,
  getAllowedItems,
  loginSupervisor,
  supervisorStore,
  updateStoreRiders,
  getEstimateTime,
  deleteAddress,
  agentUpdate,
  profileUpdateByAgent,
  getSupervisorsAgents,
  getAgentById,
  getAgent,
  agentForceLogout
};
export default apiServices;
