import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Drawer,
  List,
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import { withStore } from "../../utils/store.util";

import * as Icons from "@material-ui/icons";

// styles
import useStyles from "./styles";
// components
import SidebarLink from "./components/SidebarLink/SidebarLink";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";
import { Tooltip } from "@material-ui/core";
import localStoreUtil from "../../utils/localstore.util";
import { secondary } from "../../themes/default";
import apiServices from "../../services/requestHandler";

const structure = [
  {
    id: 0,
    label: "Dashboard",
    link: "/app/dashboard",
    icon: <Icons.Dashboard />,
  },
  { id: 1, label: "Orders", link: "/app/orders", icon: <Icons.ShoppingCart /> },
  {
    id: 2,
    label: "Employee Management",
    link: "/app/employee",
    icon: <Icons.SettingsBackupRestore />,
  },
  { id: 4, label: "Goals", link: "/app/goals", icon: <Icons.GpsFixed /> },
  {
    id: 5,
    label: "Agent Management",
    link: "/app/agents",
    icon: <Icons.ContactPhone />,
  },
  {
    id: 6,
    label: "Store Riders",
    link: "/app/riders",
    icon: <Icons.TwoWheeler />,
  },
  { id: 3, label: "Reports", link: "/app/report", icon: <Icons.Report /> },
  { id: 7, label: "Logout", link: "", icon: <Icons.SettingsPower /> },
];

function Sidebar({ location, history, store }) {
  var classes = useStyles();
  var theme = useTheme();

  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();

  // local
  var [isPermanent, setPermanent] = useState(true);
  var [agent, setAgent] = useState([]);

  useEffect(function () {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  useEffect(() => {
    const checkSetInatailState = async () => {
      const getAgentData = await localStoreUtil.get_data("agent");
      if (getAgentData) {
        setAgent(getAgentData);
      }
    };
    checkSetInatailState();
  }, []);

  const handleLogout = () => {
    try {
      store.setWithRender("logOutDailogModel", true);
    } catch (error) {
      console.log(error, location);
    }
  };

  return (
    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar} />

      <div>
        <div
          style={{
            width: "45px",
            height: "45px",
            marginLeft: "auto",
            cursor: "pointer",
          }}
          onClick={() => toggleSidebar(layoutDispatch)}
        ></div>
      </div>
      {isSidebarOpened ? (
        <>
          <div className={classes.imgMainDiv}>
            <div className={classes.imgMainContainer}>
              <img
                src={
                  agent?.image
                    ? agent?.image
                    : "https://png.pngtree.com/png-clipart/20190924/original/pngtree-business-user-profile-vector-png-image_4830519.jpg"
                }
                alt={"Profile"}
                className={classes.imgClass}
              />
              <div className={classes.imgOnlineIcon}></div>
            </div>
            <div
              style={{
                fontSize: "25px",
              }}
            >
              {agent?.name}
            </div>
            <div>
              {agent?.isStoreSupervisor && !agent?.isAgent
                ? "Store Supervisor"
                : agent?.isSupervisor
                ? "Supervisor"
                : agent?.isIntern
                ? "Intern"
                : "Caller Agent"}
            </div>
          </div>

          <List className={classes.sidebarList}>
            <hr className={classes.marginPadding} />
            {structure.map((link, index) =>
              agent.isIntern && link.id !== 3 && link.id !== 2 ? (
                <li className={classes.marginPadding} key={index}>
                  <SidebarLink
                    key={link.id}
                    location={location}
                    isSidebarOpened={isSidebarOpened}
                    {...link}
                    handleLogout={handleLogout}
                    disabled={true}
                  />
                </li>
              ) : !agent.isIntern ? (
                <li className={classes.marginPadding} key={index}>
                  <SidebarLink
                    key={link.id}
                    location={location}
                    isSidebarOpened={isSidebarOpened}
                    {...link}
                    handleLogout={handleLogout}
                    disabled={true}
                  />
                </li>
              ) : null
            )}
          </List>
        </>
      ) : (
        <>
          <div className={classes.imgMainDiv}>
            <div className={classes.imgMainContainer}></div>
          </div>

          <List className={classes.sidebarList}>
            <hr className={classes.marginPadding} />
            {structure.map((link) => (
              <Tooltip title={link.label} placement="right">
                <React.Fragment
                  className={classes.marginPadding}
                  style={{ backgroundColor: "#f9f9f9" }}
                >
                  <SidebarLink
                    key={link.id}
                    location={location}
                    isSidebarOpened={isSidebarOpened}
                    {...link}
                  />
                  <hr className={classes.marginPadding} />
                </React.Fragment>
              </Tooltip>
            ))}
          </List>
        </>
      )}

      <Dialog
        open={store.get("logOutDailogModel") || false}
        maxWidth={"sm"}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent
          style={{
            backgroundColor: "#eaf0f0",
            width: "100%",
            paddingTop: "0px",
            paddingBotton: "0px",
          }}
        >
          <div
            style={{
              width: "500px",
              textAlign: "center",
              fontWeight: "bold",
              minHeight: "50px",
            }}
          >
            <p style={{ color: "black", fontSize: "20px" }}>
              <strong>Are You Sure You Want to Logout ?</strong>
            </p>
            {/* <p>{props?.deliveryInstructions}</p> */}
          </div>
        </DialogContent>
        <DialogActions
          style={{
            backgroundColor: "#eaf0f0",
            width: "100%",
            display: "flex",
            flexWrap: "nowrap",
            flexDirection: "row",
            alignContent: "center",
            justifyContent: "center",
            alignItems: "center",
            paddingBottom: "31px",
            paddingTop: "0px",
          }}
        >
          <Button
            onClick={async () => {
              const user = await localStoreUtil.get_data("agent");
              const _id = user._id;
              await apiServices.logoutApi(_id);
              store.setWithRender("logOutDailogModel", false);
              await localStoreUtil.remove_all();
              window.location.reload();
            }}
            variant="contained"
            color="primary"
            style={{
              color: "#fff",
              width: "100px",
              backgroundColor: secondary,
              height: "48px",
            }}
          >
            <strong>Yes</strong>
          </Button>

          <Button
            onClick={() => {
              store.setWithRender("logOutDailogModel", false);
            }}
            style={{
              color: "#fff",
              width: "100px",
              backgroundColor: "#0078ac",
              height: "48px",
              marginRight: "21px",
            }}
            variant="contained"
          >
            <strong>No</strong>
          </Button>
        </DialogActions>
      </Dialog>
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(true);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withStore(withRouter(Sidebar));
