import tinycolor from 'tinycolor2';

export const primary = '#0078ac';
export const secondary = '#d2112c';
export const clickedColor = '#d16632';
const warning = '#FFC260';
const success = '#3CD4A0';
const info = '#9013FE';

const lightenRate = 7.5;
const darkenRate = 15;

const defaultTheme = {
	palette: {
		primary: {
			main: primary,
			light: tinycolor(primary).lighten(lightenRate).toHexString(),
			dark: tinycolor(primary).darken(darkenRate).toHexString(),
		},
		secondary: {
			main: secondary,
			light: tinycolor(secondary).lighten(lightenRate).toHexString(),
			dark: tinycolor(secondary).darken(darkenRate).toHexString(),
			contrastText: '#FFFFFF',
		},
		warning: {
			main: warning,
			light: tinycolor(warning).lighten(lightenRate).toHexString(),
			dark: tinycolor(warning).darken(darkenRate).toHexString(),
		},
		success: {
			main: success,
			light: tinycolor(success).lighten(lightenRate).toHexString(),
			dark: tinycolor(success).darken(darkenRate).toHexString(),
		},
		info: {
			main: info,
			light: tinycolor(info).lighten(lightenRate).toHexString(),
			dark: tinycolor(info).darken(darkenRate).toHexString(),
		},
		text: {
			primary: '#4A4A4A',
			secondary: '#6E6E6E',
			hint: '#B9B9B9',
		},
		background: {
			default: '#fff',
			light: '#F3F5FF',
		},
	},
	customShadows: {
		widget: '0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
		widgetDark: '0px 3px 18px 0px #4558A3B3, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
		widgetWide: '0px 12px 33px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
	},
	overrides: {
		MuiBackdrop: {
			root: {
				backgroundColor: '#0000009e',
			},
		},
		MuiMenu: {
			paper: {
				boxShadow: '0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
			},
		},
		MuiSelect: {
			icon: {
				color: '#B9B9B9',
			},
		},
		MuiListItem: {
			root: {
				'&$selected': {
					backgroundColor: '#F3F5FF !important',
					'&:focus': {
						backgroundColor: '#F3F5FF',
					},
				},
			},
			button: {
				'&:hover, &:focus': {
					backgroundColor: '#F3F5FF',
				},
			},
		},
		MuiTabs: {
			root: {
			},
			indicator: {
				backgroundColor: 'var(--dominos_red) !important',
				top: "59px !important",
				width: "10% !important"
			}
		},
		MuiTouchRipple: {
			child: {
				backgroundColor: 'white',
			},
		},
		MuiTableRow: {
			root: {
				'&:nth-of-type(odd)': {
					backgroundColor: "#F1F2F2",
				},
				'&:nth-of-type(even)': {
					backgroundColor: "#E6E7E8",
				},
			},
		},
		MuiTableCell: {
			root: {
				borderBottom: '1px solid rgba(224, 224, 224, .5)',
				padding: "3px 10px",
				verticalAlign: "text-bottom",
			},
			head: {
				// fontSize: '0.95rem',
			},
			body: {
				// fontSize: '0.95rem',
			},
		},
		PrivateSwitchBase: {
			root: {
				marginLeft: 10,
			},
		},
	},
};

export default defaultTheme;
