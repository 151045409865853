// import React from "react";
// import {
//   Grid,
// } from "@material-ui/core";
// // styles
// import useStyles from "./styles";
// // import CustomChart from "./components/CustomChart";
// // components
// import Widget from "../../components/Widget";
// import Charts from "../charts/Charts";
// // import TotalBenificiary from "../../images/dashboard-icon/total beneficiaries.png";
// // import totaldonors from "../../images/dashboard-icon/totaldonors.png";
// export default function Dashboard(props) {
//   var classes = useStyles();
//   const numFormatter = (num = 123321) => {
//     if (num > 999 && num < 1000000) {
//       return (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
//     } else if (num > 1000000) {
//       return (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
//     } else if (num < 900) {
//       return num; // if value < 1000, nothing to do
//     }
//   };
//   console.log(numFormatter());
//   return (
//     <div style={{overflow:'hidden'}}>
//     <div className={classes.fakeToolbar}/>
//       <Grid container spacing={4}>
//         <Grid item lg={4} md={8} sm={6} xs={12}>
//           <Widget
//             title="Total No. of Orders"
//             upperTitle
//             bodyClass={classes.fullHeightBody}
//             className={classes.card}
//             disableWidgetMenu={true}
//           >
//             <div className={classes.visitsNumberContainer}>
//               <Grid container>
//                 <Grid item xs={8}>
//                   <span className="widgetHeading">100k</span>
//                 </Grid>
//                 {/* <Grid item xs={4}>
//                   <img src={totaldonors} alt="img" />
//                 </Grid> */}
//               </Grid>
//             </div>
//           </Widget>
//         </Grid>
//         <Grid item lg={4} md={4} sm={6} xs={12}>
//           <Widget
//             title="Total No. of Riders"
//             upperTitle
//             bodyClass={classes.fullHeightBody}
//             className={classes.card}
//             disableWidgetMenu={true}
//           >
//             <div className={classes.visitsNumberContainer}>
//               <Grid container>
//                 <Grid item xs={8}>
//                   <span className="widgetHeading">40</span>
//                 </Grid>
//                 {/* <Grid item xs={4}>
//                   <img src={TotalBenificiary} alt="img" />
//                 </Grid> */}
//               </Grid>
//             </div>
//           </Widget>
//         </Grid>
//         <Grid item lg={4} md={8} sm={6} xs={12}>
//           <Widget
//             title="Total No. of Vehicles"
//             upperTitle
//             bodyClass={classes.fullHeightBody}
//             className={classes.card}
//             disableWidgetMenu={true}
//           >
//             <div className={classes.visitsNumberContainer}>
//               <Grid container>
//                 <Grid item xs={8}>
//                   <span className="widgetHeading">50</span>
//                 </Grid>
//                 {/* <Grid item xs={4}>
//                   <img src={TotalBenificiary} alt="img" />
//                 </Grid> */}
//               </Grid>
//             </div>
//           </Widget>
//         </Grid>
//         <Grid item xs={12}>
//           <Widget
//             title={
//               <h3>Orders</h3>
//             }
//             upperTitle
//             bodyClass={classes.fullHeightBody}
//             className={classes.card}
//             disableWidgetMenu={true}
//           >
//             <div className="chart-dashboard-postion">
//               <Charts />
//             </div>
//           </Widget>
//         </Grid>

//       </Grid>
//     </div>
//   );
// }
import React from 'react'

const Dashboard = () => {
  return (
    <div>
      Dashboard components
    </div>
  )
}

export default Dashboard
