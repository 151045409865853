import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import localStoreUtil from "./utils/localstore.util";
import { withStore } from "./utils/store.util";

export const askForPermissioToReceiveNotifications = async (props) => {
  const firebaseConfig = {
    apiKey: "AIzaSyD_UzMbNjFdwN7MWTFonfEW4KgTF13MPqw",
    authDomain: "dominos-cb2b2.firebaseapp.com",
    projectId: "dominos-cb2b2",
    storageBucket: "dominos-cb2b2.appspot.com",
    messagingSenderId: "674252434710",
    appId: "1:674252434710:web:255025285570ccd487f437",
    measurementId: "G-09KV02YRLS",
  };
  const fapp = initializeApp(firebaseConfig);
  const messaging = getMessaging(fapp);

  getToken(messaging, {
    vapidKey:
      "BApdOG74rRYsoxbt0dK1_lgQbgteqxrXkiLdEXfvLVZbAZsMzpsdEgE4CNZh3uXdcdGGOWKquD-0tUMBChH3B98",
  })
    .then(async (currentToken) => {
      if (currentToken) {
       await localStoreUtil.store_data("deviceToken", currentToken);
        //   console.log("Firebase Token", currentToken)
      } else {
        // Show permission request UI
        console.log(
          "No registration token available. Request permission to generate one."
        );
        // ...
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
      // ...
    });

  onMessage(messaging, async (payload) => {
    console.log("onMessage: ", payload);
    await localStoreUtil.store_data("payload" , payload);
    await props.store.setWithRender("payload" , true);
    navigator.serviceWorker
      .getRegistration("/firebase-cloud-messaging-push-scope")
      .then((registration) => {
        registration.showNotification(
          payload.notification.title,
          payload.notification
        );
      });
    // ...
  });

  /*firebase daniel end*/
};

export default withStore(askForPermissioToReceiveNotifications);
