import React, { Component } from "react";
import EmployeeComponant from "./EmployeeComponant";

export class EmployeeContainer extends Component {
 

  render() {
    return (
      <>
        <EmployeeComponant />

      </>
    );
  }
}

export default EmployeeContainer;
