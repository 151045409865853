import React, { Component } from "react";
import { Button } from "@material-ui/core";
import { Card, CardHeader } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import apiServices from "../../services/requestHandler";
import MUIDataTable from "mui-datatables";
import moment from "moment";
import localStoreUtil from "../../utils/localstore.util";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import "../../App.css";

const myTheme = createMuiTheme({
  overrides: {
    MUIDataTable: {
      responsiveScroll: {
        maxHeight: "100% !important",
      },
    },
  },
});

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
});

class subAgentList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedInAgent: {},
      agents: [],
      coulumns: [
        {
          name: "id",
          options: {
            display: false,
            download: false,
            filter: false,
            viewColumns: false,
          },
        },
        "Agent ID",
        "Name",
        "Mobile",
        "Email",
        "Created At",
        "Status",
        "Logout Agent",
      ],
      loader: true,
      check: true,
      user: [],
    };
  }

  async componentDidMount() {
    this.setState({
      user: await localStoreUtil.get_data("token"),
      loggedInAgent: await localStoreUtil.get_data("agent"),
    });

    this.getSubAgents();
  }

  logOutAgent = async (_id) => {
    try {
      await apiServices.agentForceLogout(_id);

      this.getSubAgents();
    } catch (e) {
      this.setState({
        loader: false,
      });
    }
  };

  getSubAgents = async () => {
    try {
      const agent_ID = this.state.loggedInAgent?._id;
      const response = await apiServices.getSupervisorsAgents(agent_ID);
      let agentResponse = [];

      (response.data.supervisorAgents || []).map((item) => {
        return agentResponse.push([
          item._id,
          item.agentID || "-",
          item.name || "-",
          `${item.countryCode} ${item.mobile}`,
          item.email || "-",
          moment(item.createdAt).format("DD.MMM.YYYY H:mm") || "-",
          item.active ? "Active" : "Inactive",
          <>
            <Button
              type={"button"}
              className={
                item?.isLogin === true
                  ? "btn-red-outlined"
                  : "btn-gray-outlined"
              }
              variant="contained"
              disabled={item?.isLogin === true ? false : true}
            >
              FORCEFULLY LOGOUT
            </Button>
          </>,
        ]);
      });

      await this.setState({ agents: agentResponse, loader: false });
    } catch (error) {
      this.setState({
        loader: false,
      });
    }
  };

  onRowClick = async (colValue, colIndex) => {
    if (colIndex.colIndex === 7) {
      this.logOutAgent(this.state.agents[colIndex.dataIndex][0]);
    } else {
      this.props.history.push({
        pathname: `/app/getByid/${
          this.state.agents[colIndex.dataIndex][0]
        }`,
      });
    }
  };

  render() {
    const { agents, coulumns } = this.state;
    const options = {
      filterType: "multiselect",
      elevation: 0,
      downloadOptions: { filename: "Agents.csv" },
      onCellClick: (colValue, colIndex) => this.onRowClick(colValue, colIndex),
      textLabels: {
        body: {
          noMatch: `${"Sorry, No Matching Records Found"}`,
        },
      },
      rowsPerPageOptions: [10, 25, 50, 100],
      selectableRows: false,
      responsive: "scroll",
      print: false,
    };
    return (
      <div className="res-container">
        <Card elevation={2}>
          <CardHeader
            className={"blue-background"}
            title={
              <React.Fragment>
                <div style={{ display: "flex" }}>
                  <div style={{ flex: 1 }}>Agents</div>
                  <div className={"btnDiv"}>
                    {this.state.user.agents?.create ? (
                      <Button
                        className={"btn-blue-outlined"}
                        variant="contained"
                        onClick={() =>
                          this.props.history.push({
                            pathname: `/AgentManAdd`,
                          })
                        }
                        startIcon={<AddCircleIcon />}
                      >
                        Add Agent
                      </Button>
                    ) : null}
                  </div>
                </div>
              </React.Fragment>
            }
          />
          <div id={"table-ui-agent"}>
            <MuiThemeProvider theme={myTheme}>
              <MUIDataTable
                title={
                  <h6 id="total-coupons">
                    Total Agents: {this.state.agents.length || 0}
                  </h6>
                }
                id={"mui-tax-table"}
                data={agents}
                columns={coulumns}
                options={options}
              />
            </MuiThemeProvider>
          </div>
        </Card>
      </div>
    );
  }
}

export default withStyles(styles)(subAgentList);
