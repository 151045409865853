import {makeStyles} from "@material-ui/styles";

export default makeStyles((theme) => ({
  container: {
    height: "100vh",
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: 0,
    left: 0,
  },
  copyrightHover: {
    color: "white",
    textDecoration: 'none',
    fontWeight: 500
  },
  footer: {
    position: "absolute",
    bottom: "10px",
  },
  overlay: {
    backgroundColor: "black",
    width: " 100%",
    height: "100vh",
    position: "relative",
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
  },
  loginBackImg: {
    opacity: "0.2",

    width: "100%",
    height: "100vh",
    objectFit: "cover",
    position: "relative",
  },
  logoLogin: {
    position: "absolute",
    top: "0",
    left: "0",
    right: "0",
    height: "100vh",
    // backgroundColor:'red',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  logoSize: {
    width: "300px",
    objectFit: "contain"
  },
  lableSize: {
    fontSize: "15px",
    color: "white",
  },
  forgotPasswordLabel: {
    fontSize: "14px",
    color: "white",
    fontWeight: "bold",
    textAlign: "center",

    "&:hover": {color: "var(--dominos_red)"},
  },
  supervisorLoginLabel: {
    fontSize: "14px",
    color: "white",
    fontWeight: "bold",

    "&:hover": {color: "var(--dominos_red)"},
  },
  textBackground: {
    backgroundColor: "white",
    height: "40px",
    borderRadius: "5px",
    maxWidth: "246px",
    width: "246px"
  },
  logotypeContainer: {
    backgroundColor: "rgb(0 45 0)",
    width: "60%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      width: "50%",
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  logotypeImage: {
    width: 300,
    objectFit: "contain",
    marginBottom: theme.spacing(4),
  },
  logotypeText: {
    color: "white",
    fontWeight: 500,
    fontSize: 84,
    [theme.breakpoints.down("md")]: {
      fontSize: 48,
    },
  },
  formContainer: {
    width: "40%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      width: "50%",
    },
  },
  form: {
    width: 320,
  },
  tab: {
    fontWeight: 400,
    fontSize: 18,
  },
  greeting: {
    fontWeight: 500,
    textAlign: "center",
    marginTop: theme.spacing(4),
  },
  subGreeting: {
    fontWeight: 500,
    textAlign: "center",
    marginTop: theme.spacing(2),
  },
  googleButton: {
    marginTop: theme.spacing(6),
    boxShadow: theme.customShadows.widget,
    backgroundColor: "white",
    width: "100%",
    textTransform: "none",
  },
  googleButtonCreating: {
    marginTop: 0,
  },
  googleIcon: {
    width: 30,
    marginRight: theme.spacing(2),
  },
  creatingButtonContainer: {
    marginTop: theme.spacing(2.5),
    height: 46,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  createAccountButton: {
    height: 46,
    textTransform: "none",
  },
  formDividerContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: "flex",
    alignItems: "center",
  },
  formDividerWord: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  formDivider: {
    flexGrow: 1,
    height: 1,
    backgroundColor: theme.palette.text.hint + "40",
  },
  errorMessage: {
    textAlign: "center",
  },
  textFieldUnderline: {
    "&:before": {
      borderBottomColor: theme.palette.primary.light,
    },
    "&:after": {
      borderBottomColor: theme.palette.primary.main,
    },
    "&:hover:before": {
      borderBottomColor: `${theme.palette.primary.light} !important`,
    },
  },
  textField: {
    borderBottomColor: theme.palette.background.light,
  },
  formButtons: {
    width: "100%",
    marginTop: theme.spacing(4),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  forgetButton: {
    textTransform: "none",
    fontWeight: 400,
  },
  loginLoader: {
    marginLeft: theme.spacing(4),
  },
  copyright: {
    marginTop: theme.spacing(4),
    whiteSpace: "nowrap",
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      bottom: theme.spacing(2),
    },
  },
  disabledBackground: {
    backgroundColor: "gray",
    height: "40px",
    borderRadius: "5px",
    color: "white",
    zIndex: "50",
  },
}));
