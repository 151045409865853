import React from 'react';
import { AppBar, Toolbar } from '@material-ui/core';
import Clock from 'react-live-clock';
// styles
import useStyles from './styles';

// components
import logo from '../../images/logo/app_logo_white.png';
import moment from 'moment';
// context

export default function Header(props) {
	var classes = useStyles();

	// global

	// local

	return (
		<AppBar position="fixed" className={classes.appBar} elevation={0}>
			<Toolbar className={classes.toolbar}>
				{/* <Typography variant="h6" weight="medium" className={classes.logotype}>
					Dominos's */}
				<img src={logo} alt={''} className={classes.imageClassLogo} />
				{/* </Typography> */}
				<div className={classes.grow} />
				<div className={classes.displayFlex}>
					<div className={classes.timeAndDateSide}>
						<p className={classes.marginPadding}>Today's Date</p>
						<span className={classes.timeAndDateSideFont}>
							{moment(new Date()).format('dddd, D MMMM YYYY')}
						</span>
					</div>
					<div className={classes.timeAndDateSide}>
						<p className={classes.marginPadding}>Current Time</p>
						<span className={classes.timeAndDateSideFont}>
							<Clock format={'HH:mm:ss'} ticking={true}  interval={1000} />
						</span>
					</div>
				</div>
			</Toolbar>
		</AppBar>
	);
}
