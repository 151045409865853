/**
 * Feature Constants // Define Project constants here
 */

const features = {
    login: 'login',
    user: 'user',
    menuType : '',
    crust : '',
    blank : '',
    logout: '',
}

export default features
