/**
 * Misc. functions
 */

import { getDistance } from "geolib";
import _ from "lodash";
import moment from "moment";
import apiServices from "../services/requestHandler";
import FontFaceObserver from "fontfaceobserver";

export const CUSTOM_PIZZA_CODE = "08PIREZA";

// first letter capitalize
export const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

export const currency_format = (value) => {
  return Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);
};

export const errorCapitalize = (str) =>
  str.charAt(1).toUpperCase() + str.slice(2);

export const capital_letter = (str) => {
  str = str.split(" ");

  for (var i = 0, x = str.length; i < x; i++) {
    str[i] = str[i][0].toUpperCase() + str[i].substr(1);
  }

  return str.join(" ");
};

export const defaultCutsName = {
  "Double Cut": "DoubleCut",
  "Square Cut": "SquareCut",
  "": "RegularCut",
};

export const defaultPismis = {
  Azpismis: "Az Pismis",
  Cookpismis: "Cook Pismis",
  "": "",
};

export const defaultPismisName = {
  "Az Pismis": "Azpismis",
  "Cook Pismis": "Cookpismis",
  "": "",
};

export const serviceMethods = {
  delivery: "Delivery",
  takeaway: "Take Away",
  dineIn: "Dine In",
  dinein: "Dine In",
  "": "Not Available",
};

export const paymentMethods = {
  cash: "Cash on Delivery",
  card: "Card on Delivery",
  online: "Credit or Debit Card",
  "": "Not Available",
};

// get status colors
export const getColor = (type) => {
  switch (type) {
    case "pending": {
      return "#ffc107";
    }
    case "delivered": {
      return "#28a745";
    }
    case "accepted": {
      return "#6c757d";
    }
    case "picked": {
      return "#6c757d";
    }
    case "onmyway": {
      return "#17a2b8";
    }
    case "cancelled": {
      return "#dc3545";
    }
    default: {
      return null;
    }
  }
};

export const defaultChannels = {
  web: "Website",
  android: "Android",
  ios: "iOS",
  callcenter: "Call Center",
  pulse: "Walk in Customer",
  "": "Unknown",
};

export const staticIps = {
  "192.140.145.96": "192.140.145.96", // Hitech-Prime Ip
  "85.132.36.4": "85.132.36.4", //Call-Center (Azintex),
  "185.52.131.74": "185.52.131.74", // Bakcell WiMax
  "185.52.131.115": "185.52.131.115", // Bahram Bakcell Data Card
  "85.132.10.6": "85.132.10.6", // Bahram Home
  "101.53.236.37": "101.53.236.37", // Salman Bhai Home
  "103.12.121.147": "103.12.121.147", // Khizer Home

  // AFS Public IPs
  "39.57.194.18": "39.57.194.18",
  "103.11.0.251": "103.11.0.251",
  "124.29.232.61": "124.29.232.61",
  "103.228.157.94": "103.228.157.94",
  "182.191.55.124": "182.191.55.124",
};

export const getNewColors = (type) => {
  switch (type) {
    case "pending": {
      // rgb(255,183,128)
      return "#f00";
    }
    case "delivered": {
      return "#00bd39";
    }
    case "accepted": {
      return "#e0ca05";
    }
    case "secondary": {
      return "rgb(250, 120, 22)";
    }
    case "blue": {
      return "#042954";
    }
    case "red": {
      return "#b10000";
    }
    case "gray": {
      return "#646464";
    }
    case "dark-gray": {
      return "#6f6f6f";
    }
    case "light-gray": {
      return "#a7a7a7";
    }
    case "bg-gray": {
      return "#f1f1f1";
    }
    default: {
      return null;
    }
  }
};

export const getFinancesColors = (type) => {
  switch (type) {
    case "pending": {
      return "rgb(255,199,156)";
    }
    case "pending-bg": {
      return "rgb(255,183,128,0.2)";
    }
    case "paid": {
      return "rgb(94,204,127)";
    }
    case "paid-bg": {
      return "rgb(0,189,57,0.2)";
    }
    default: {
      return "";
    }
  }
};

// deep clone an object
export const deepClone = (obj) => {
  var copy;

  // eslint-disable-next-line
  if (obj == null || typeof obj != "object") {
    return obj;
  }

  // Handle Array
  if (obj instanceof Array) {
    copy = [];
    for (var i = 0, len = obj.length; i < len; i++) {
      copy[i] = deepClone(obj[i]);
    }
    return copy;
  }

  // Handle Object
  if (obj instanceof Object) {
    copy = {};
    for (var attr in obj) {
      if (obj.hasOwnProperty(attr)) {
        copy[attr] = deepClone(obj[attr]);
      }
    }
    return copy;
  }

  throw new Error("Unable to copy obj! Its type isn't supported.");
};

// check if empty or not
export const isEmpty = (object, dataType = "object") => {
  switch (dataType) {
    case "object": {
      return !Object.keys(object).length;
    }
    default: {
      return false;
    }
  }
};

// format time
export const formatTime = (time) => {
  const timeArray = time.split(":");
  let timeToReturn = "";

  if (timeArray.length) {
    if (timeArray[0] > 12) {
      timeToReturn = `${timeArray[0] - 12}:${timeArray[1]}`;
    }
  }

  return timeToReturn;
};

//get store by distance
export const sortStoresByDistance = (stores, location) => {
  let allStores = (stores || []).map((store) => {
    const distance = (
      getDistance(
        { latitude: location.latitude, longitude: location.longitude },
        { latitude: store.location[1], longitude: store.location[0] }
      ) / 1000
    ).toFixed(1);
    return {
      ...store,
      distance: parseFloat(distance),
    };
  });
  allStores = allStores.sort((a, b) => {
    return a.distance - b.distance;
  });
  return allStores;
};

//Trim order number or any given number
export const getTrimmedOrderNumber = (orderNumber) => {
  try {
    const orderNumberLength = 16;
    // let trimmedOrderNumber = '';
    if ((`${orderNumber}` || "").length > 8) {
      return `${orderNumber}`.substring(8, orderNumberLength);
    } else {
      return `${orderNumber}`;
    }
  } catch (error) {
    console.log(error);
  }
};

//check if object has any true value in it or not
export const checkTrueValue = (obj) => {
  const valuesOfObject = Object.values(obj);
  const TrueFalse = valuesOfObject.includes(true);
  return TrueFalse;
};

//Remove a value from array
export const removerValue = (item, index, qty) => {
  const selectedItem = item?.splice(index, qty);
  const finalArray = item?.filter((item) => {
    return item !== selectedItem;
  });
  return finalArray;
};

//Return Pizza Add Error Msg
export const pizaAddErrors = (obj) => {
  if (
    !obj?.pizza?.item ||
    obj?.pizza?.item === undefined ||
    obj?.pizza?.item === ""
  ) {
    return "Please Select Pizza";
  } else if (obj?.pizza?.size === undefined || obj?.pizza?.size === "") {
    return "Please Select Size";
  } else if (
    !obj?.pizza?.cruust ||
    obj?.pizza?.cruust === undefined ||
    obj?.pizza?.cruust === ""
  ) {
    return "Please Select Crust";
  } else if (!obj?.pizza?.item.Whole && obj?.pizza?.item?.Left === undefined) {
    return "Please Select Left Pizza";
  } else if (!obj?.pizza?.item.Whole && obj?.pizza?.item?.Right === undefined) {
    return "Please Select Right Pizza";
  } else {
    return "NoError";
  }
};

export const emptyLanguageHandling = () => {
  return {
    en: "",
    az: "",
    ru: "",
  };
};

//Return Everything Else Error Msg
export const drinksAddErrors = (obj) => {
  try {
    if (
      obj?.everythingElse === undefined ||
      obj?.everythingElse === "" ||
      Object.keys(obj?.everythingElse).length === 0
    ) {
      return "Please Select Item";
    } else if (
      (obj?.everythingElse?.size === undefined ||
        obj?.everythingElse?.size === "" ||
        Object.keys(obj?.everythingElse?.size).length === 0) &&
      obj?.subType === "Drink"
    ) {
      return "Please Select Size";
    } else if (
      (!obj?.everythingElse?.item ||
        obj?.everythingElse?.item === undefined ||
        Object.keys(obj?.everythingElse?.item).length === 0 ||
        obj?.everythingElse?.item === "") &&
      obj?.subType === "Drink"
    ) {
      return "Please Select Drink";
    } else if (
      (!obj?.everythingElse?.flavor ||
        obj?.everythingElse?.flavor === undefined ||
        obj?.everythingElse?.flavor === "" ||
        Object.keys(obj?.everythingElse?.flavor).length === 0) &&
      obj?.subType === "Drink" &&
      obj?.everythingElse?.flavorKey
    ) {
      return "Please Select Flavor";
    } else {
      return "NoError";
    }
  } catch (error) {
    console.log(error);
  }
};

//Check For Numbers Only
export const isNumber = (mobile) => {
  if (mobile) {
    let regex = new RegExp(/^[0-9]*$/);
    return regex.test(mobile);
  } else return false;
};

// Check WHITE space
export const checkWhiteSpace = (text) => {
  const _text = text.trim();
  return text === _text;
};

// remove duplicated toppings
export const removeDuplicates = (array) => {
  try {
    const reduced = array.reduce((acc, curr) => {
      const text = curr._id;
      acc[text] = acc[text] || 0;
      acc[text]++;
      return acc;
    }, {});

    let uniq = {};
    let uniqueItems = array.filter(
      (obj) => !uniq[obj._id] && (uniq[obj._id] = true)
    );
    uniqueItems.itemQty = reduced;
    return uniqueItems;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const slices = {
  small: [
    {
      label: "Regular Cut",
      value: 4,
    },
    {
      label: "Double Cut",
      value: 8,
    },
    {
      label: "Square Cut",
      value: 9,
    },
  ],
  medium: [
    {
      label: "Regular Cut",
      value: 6,
    },
    {
      label: "Double Cut",
      value: 12,
    },
    {
      label: "Square Cut",
      value: 16,
    },
  ],
  large: [
    {
      label: "Regular Cut",
      value: 8,
    },
    {
      label: "Double Cut",
      value: 16, //remainder to floor the value of slices
    },
    {
      label: "Square Cut",
      value: 16.000000001,
    },
  ],
};

export const getSliceLabel = (size, sliceValue) => {
  try {
    const slice = slices[(size || "").toLowerCase()];
    const selectedSlice = (slice || []).filter((_slice) => {
      return (_slice || {}).value === sliceValue;
    });
    return (selectedSlice[0] || {}).label;
  } catch (error) {
    console.log(error);
  }
};

export const detailPanelOrder = async (_order) => {
  try {
    let order = await apiServices.getOrderById((_order || {})._id);
    // order = order.data;
    const items = order.data.data.items.map((item) => {
      let _item = {
        ...item,
        ...item.item,
        productQuantity: item.quantity,
        totalPrice: item.price ? parseFloat((item || {}).price) : 0.0,
        discountPrice: (item || {}).actualPrice || 0,
        priceBeforeCoupon: (item || {}).priceBeforeCoupon || 0,
      };
      if (item.deal !== undefined) {
        // const offer = await apiServices.getDealById(item.deal._id);
        let dealItemsArr = item.dealItems.map((__item) => {
          let dealItem = {
            ...__item.item,
            productQuantity: __item.quantity,
            dealCardId: (__item || {}).dealCardId || 0,
          };
          let std = [];
          let extra = [];
          if ((((__item || {}).item || {}).type || {}) === "pizza") {
            std = __item.standardToppings.map((topping) => {
              return topping._id;
            });
            extra = __item.extraToppings.map((topping) => {
              return topping._id;
            });
            const removeStd = __item.item.toppings.filter((_topping) => {
              return !__item.standardToppings.some((__topping) => {
                return __topping.id === _topping.id;
              });
            });
            const removeName = removeStd.map((_topping) => {
              return _topping.name;
            });
            dealItem = {
              ...dealItem,
              standardToppings: std,
              extraToppings: extra,
              dough: ((__item || {}).dough || {})._id,
              // allowedItems: __item.allowedItems,
              // doughDetails: (__item || {}).dough,
              slices: __item.slices,
              size: __item.size,
              sliceName: getSliceLabel(__item.size, __item.slices),
              doughName: ((__item || {}).dough || {}).name,
              toppingName: __item.standardToppings,
              extraToppingName: __item.extraToppings,
              selectedStandardToppings: __item.standardToppings,
              freeToppingData: removeStd,
              freeToppingNames: removeName,
            };
            if ((__item || {}).edge) {
              dealItem.edge = ((__item || {}).edge || {})._id;
              // dealItem.edgeDetails = (__item || {}).edge;
              dealItem.edgeName = ((__item || {}).edge || {}).name;
            }
          }
          return { item: dealItem };
        });
        _item = {
          ..._item,
          deal: item.deal._id,
          ...item.deal,
          dealItems: item.dealItems,
          myDealItems: dealItemsArr,
          discountPrice: (item || {}).actualPrice || 0,
          type: "deal",
        };
      } else if (item.type === "half_half") {
        const std1 = item.dealItems[0].standardToppings.map((topping) => {
          return topping._id;
        });
        const extra1 = item.dealItems[0].extraToppings.map((topping) => {
          return topping._id;
        });
        const std2 = item.dealItems[1].standardToppings.map((topping) => {
          return topping._id;
        });
        const extra2 = item.dealItems[1].extraToppings.map((topping) => {
          return topping._id;
        });

        const removefirst = item.dealItems[0].item.toppings.filter(
          (_topping) => {
            return !item.dealItems[0].standardToppings.some((__topping) => {
              return __topping._id === _topping._id;
            });
          }
        );

        const removeSecond = item.dealItems[1].item.toppings.filter(
          (_topping) => {
            return !item.dealItems[1].standardToppings.some((__topping) => {
              return __topping._id === _topping._id;
            });
          }
        );
        const dealItem1 = {
          ...item.dealItems[0],
          standardToppings: std1,
          extraToppings: item.dealItems[0].extraToppings,
        };
        const dealItem2 = {
          ...item.dealItems[1],
          standardToppings: std2,
          extraToppings: item.dealItems[1].extraToppings,
        };
        const halfItems = [
          {
            item: (item.dealItems[0].item || {})._id,
            extraToppings: extra1,
            standardToppings: std1,
          },
          {
            item: (item.dealItems[1].item || {})._id,
            extraToppings: extra2,
            standardToppings: std2,
          },
        ];
        const doughPrice = ((item || {}).dough || {}).price[item.size];
        const leftPizzaPrice = item.dealItems[0].item.sizes[item.size] / 2;
        const rightPizzaPrice = item.dealItems[1].item.sizes[item.size] / 2;
        _item = {
          ..._item,
          name: {
            en: "Half n Half",
            az: "Yarı n Yarısı",
            ru: "Половина Половина",
          },
          sliceName: getSliceLabel((_item || {}).size, (_item || {}).slices),
          slice: (item || {}).slices,
          dough: ((item || {}).dough || {})._id,
          doughName: ((item || {}).dough || {}).name,
          doughPrice: doughPrice,
          firstFreeData: removefirst,
          secondFreeData: removeSecond,
          firstHalfToppingsData: item.dealItems[0].extraToppings,
          secondHalfToppingsData: item.dealItems[1].extraToppings,
          // edge: ((item || {}).edge || {})._id,
          items: halfItems,
          type: "half_half",
          leftPizzaPrice,
          rightPizzaPrice,
          deal: "Half n Half",
          myDealItems: [
            { ...dealItem1, ...item.dealItems[0].item },
            { ...dealItem2, ...item.dealItems[1].item },
          ],
        };
        if ((item || {}).edge) {
          const edgePrice = ((item || {}).edge || {}).price[item.size];
          _item.edge = ((item || {}).edge || {})._id;
          _item.edgeName = item.edge.name;
          _item.edgePrice = edgePrice;
        }
      } else if (item.item.type === "drink") {
        _item.type = "drink";
        _item.size = "defaultSize";
      } else if (item.item.type === "pizza") {
        if (item.productCode === CUSTOM_PIZZA_CODE) {
          _item.custom = "custom";
          _item.pizzaName = {
            en: "Make Your Own Pizza",
            az: "Make Your Own Pizza",
            ru: "Make Your Own Pizza",
          };
          _item.pizzaDescription = {
            en: "Can't choose? Then make your own Pizza",
            az: "Can't choose? Then make your own Pizza",
            ru: "Can't choose? Then make your own Pizza",
          };
        }
        const std = item.standardToppings.map((topping) => {
          return topping._id;
        });
        const removedStd = item.item.toppings
          .filter((_topping) => {
            return !item.standardToppings.some((__topping) => {
              return __topping._id === _topping._id;
            });
          })
          .map((_myTopping) => {
            return _myTopping._id;
          });
        let extraToppingPrice = 0;
        const extra = item.extraToppings.map((topping) => {
          extraToppingPrice += (topping || {}).sizes[item.size];
          return topping._id;
        });
        const doughPrice = ((item || {}).dough || {}).price[item.size];

        _item = {
          ..._item,
          dough: item.dough._id,
          doughName: item.dough.name,
          standardToppings: std,
          extraToppings: extra,
          extraToppingData: item.extraToppings,
          extraToppingPrice: extraToppingPrice,
          doughPrice: doughPrice,
          removedToppings: removedStd,
          size: item.size,
          slices: item.slices,
          sliceName: getSliceLabel(item.size, item.slices),
        };
        if ((item || {}).edge) {
          const edgePrice = ((item || {}).edge || {}).price[item.size];
          _item.edge = ((item || {}).edge || {})._id;
          _item.edgeName = item.edge.name;
          _item.edgePrice = edgePrice;
        }
      }
      return _item;
    });

    return items;
  } catch (error) {
    console.log("error", error);
  }
};

export const recentDetailPanel = (_order) => {
  try {
    const items = _order.items.map((item) => {
      let _item = {
        ...item,
        ...item.item,
        productQuantity: item?.quantity,
        totalPrice: item?.price ? parseFloat((item || {}).price) : 0.0,
        discountPrice: (item || {}).actualPrice || 0,
        priceBeforeCoupon: (item || {}).priceBeforeCoupon || 0,
      };
      if (item.deal !== undefined) {
        // const offer = await apiServices.getDealById(item.deal._id);
        let dealItemsArr = item?.dealItems?.map((__item) => {
          let dealItem = {
            ...__item.item,
            productQuantity: __item?.quantity,
            dealCardId: (__item || {}).dealCardId || 0,
          };
          let std = [];
          let extra = [];
          if ((((__item || {}).item || {}).type || {}) === "pizza") {
            std = __item?.standardToppings?.map((topping) => {
              return topping;
            });
            extra = __item.extraToppings?.map((topping) => {
              return topping;
            });
            const removeStd = __item?.item?.toppings?.filter((_topping) => {
              return !__item?.standardToppings?.some((__topping) => {
                return __topping.id === _topping.id;
              });
            });
            const removeName = removeStd?.map((_topping) => {
              return _topping.name;
            });
            dealItem = {
              ...dealItem,
              standardToppings: std,
              dough: ((__item || {}).dough || {})._id,
              // allowedItems: __item.allowedItems,
              // doughDetails: (__item || {}).dough,
              slices: __item.slices,
              size: __item.size,
              sliceName: getSliceLabel(__item.size, __item.slices),
              doughName: ((__item || {}).dough || {}).name,
              toppingName: __item?.standardToppings,
              extraToppingName: __item?.extraToppings,
              extraToppings: extra,
              selectedStandardToppings: __item?.standardToppings,
              freeToppingData: removeStd,
              freeToppingNames: removeName,
            };
            if ((__item || {}).edge) {
              dealItem.edge = ((__item || {}).edge || {})._id;
              // dealItem.edgeDetails = (__item || {}).edge;
              dealItem.edgeName = ((__item || {}).edge || {}).name;
            }
          }
          return { item: dealItem };
        });
        _item = {
          ..._item,
          deal: item?.deal?._id,
          ...item?.deal,
          dealItems: item?.dealItems,
          myDealItems: dealItemsArr,
          discountPrice: (item || {}).actualPrice || 0,
          type: "deal",
        };
      } else if (item.type === "half_half") {
        const std1 = item?.dealItems[0]?.standardToppings?.map((topping) => {
          return topping;
        });
        const extra1 = item?.dealItems[0]?.extraToppings?.map((topping) => {
          return topping._id;
        });
        const std2 = item?.dealItems[1]?.standardToppings?.map((topping) => {
          return topping;
        });
        const extra2 = item?.dealItems[1]?.extraToppings?.map((topping) => {
          return topping._id;
        });

        const removefirst = item?.dealItems[0]?.item?.toppings?.filter(
          (_topping) => {
            return !item?.dealItems[0]?.standardToppings?.some((__topping) => {
              return __topping._id === _topping._id;
            });
          }
        );

        const removeSecond = item?.dealItems[1]?.item?.toppings?.filter(
          (_topping) => {
            return !item?.dealItems[1]?.standardToppings?.some((__topping) => {
              return __topping._id === _topping._id;
            });
          }
        );
        const dealItem1 = {
          ...item?.dealItems[0],
          standardToppings: std1,
          extraToppings: item?.dealItems[0]?.extraToppings,
        };
        const dealItem2 = {
          ...item?.dealItems[1],
          standardToppings: std2,
          extraToppings: item?.dealItems[1]?.extraToppings,
        };
        const halfItems = [
          {
            item: (item.dealItems[0].item || {})._id,
            extraToppings: extra1,
            standardToppings: std1,
          },
          {
            item: (item.dealItems[1].item || {})._id,
            extraToppings: extra2,
            standardToppings: std2,
          },
        ];
        const doughPrice = ((item || {}).dough || {}).price[item.size];
        const leftPizzaPrice = item?.dealItems[0]?.item?.sizes[item.size] / 2;
        const rightPizzaPrice = item?.dealItems[1]?.item?.sizes[item.size] / 2;
        _item = {
          ..._item,
          name: {
            en: "Half n Half",
            az: "Yarı n Yarısı",
            ru: "Половина Половина",
          },
          sliceName: getSliceLabel((_item || {}).size, (_item || {}).slices),
          slice: (item || {}).slices,
          dough: ((item || {}).dough || {})._id,
          doughName: ((item || {}).dough || {}).name,
          doughPrice: doughPrice,
          firstFreeData: removefirst,
          secondFreeData: removeSecond,
          firstHalfToppingsData: item.dealItems[0].extraToppings,
          secondHalfToppingsData: item.dealItems[1].extraToppings,
          // edge: ((item || {}).edge || {})._id,
          items: halfItems,
          type: "half_half",
          leftPizzaPrice,
          rightPizzaPrice,
          deal: "Half n Half",
          myDealItems: [
            { ...dealItem1, ...item.dealItems[0].item },
            { ...dealItem2, ...item.dealItems[1].item },
          ],
        };
        if ((item || {}).edge) {
          const edgePrice = ((item || {}).edge || {}).price[item.size];
          _item.edge = ((item || {}).edge || {})._id;
          _item.edgeName = item.edge.name;
          _item.edgePrice = edgePrice;
        }
      } else if (item.item.type === "drink") {
        _item.type = "drink";
        _item.size = "defaultSize";
      } else if (item.item.type === "pizza") {
        if (item.productCode === CUSTOM_PIZZA_CODE) {
          _item.custom = "custom";
          _item.pizzaName = {
            en: "Make Your Own Pizza",
            az: "Make Your Own Pizza",
            ru: "Make Your Own Pizza",
          };
          _item.pizzaDescription = {
            en: "Can't choose? Then make your own Pizza",
            az: "Can't choose? Then make your own Pizza",
            ru: "Can't choose? Then make your own Pizza",
          };
        }
        // const std = item?.standardToppings.map((topping) => {
        //   return { _id: topping._id };
        // });
        const removedStd = item?.item?.toppings
          ?.filter((_topping) => {
            return !item?.standardToppings.some((__topping) => {
              return __topping._id === _topping._id;
            });
          })
          .map((_myTopping) => {
            return _myTopping._id;
          });
        let extraToppingPrice = 0;
        const extra = item?.extraToppings.map((topping) => {
          extraToppingPrice += (topping || {}).sizes[item.size];
          return topping._id;
        });
        const doughPrice = ((item || {}).dough || {}).price[item.size];

        _item = {
          ..._item,
          dough: item?.dough._id,
          doughName: item?.dough.name,
          standardToppings: item?.standardToppings,
          extraToppings: extra,
          extraToppingData: item?.extraToppings,
          extraToppingPrice: extraToppingPrice,
          doughPrice: doughPrice,
          removedToppings: removedStd,
          size: item?.size,
          slices: item?.slices,
          sliceName: getSliceLabel(item?.size, item?.slices),
        };
        if ((item || {}).edge) {
          const edgePrice = ((item || {}).edge || {}).price[item.size];
          _item.edge = ((item || {}).edge || {})._id;
          _item.edgeName = item?.edge.name;
          _item.edgePrice = edgePrice;
        }
      }
      return _item;
    });

    return items;
  } catch (error) {
    console.log("error", error);
  }
};

export const setLocalizeContent = (obj, store) => {
  try {
    return obj.en;
    // return obj[store.get("language")];
  } catch (error) {
    console.log(error);
    return { en: "", az: "", ru: "" };
  }
};

export const getToppingsDetail = (topings, allTopingsData) => {
  if (topings) {
    let finalToppingsArray = [];
    const toopingArray = allTopingsData?.filter((element) =>
      topings?.includes(element?.productCode)
    );
    toopingArray?.map((item) => {
      return finalToppingsArray.push(item);
    });
    return finalToppingsArray;
  }
};

export const getRemovedToppingsWithSize = (stdToppings) => {
  try {
    return stdToppings?.map((item) =>
      item.toppingSize?.toLowerCase() !== "standard" ? (
        <div
          style={{
            display: "flex",
          }}
        >
          {item?.toppingSize?.toLowerCase() === "standard"
            ? ""
            : item?.toppingSize?.toLowerCase() === "triple"
            ? "Double"
            : _.capitalize(item?.toppingSize) || ""}
          &nbsp;
          <span>{setLocalizeContent(item?.name || {})}</span>
        </div>
      ) : null
    );
  } catch (error) {
    console.log(error);
    return;
  }
};

export const getDealsError = async (data) => {
  try {
    let serviceMethod = ""; // Delivery / Carry-Out / Dine-In / Selct Service Method
    let store = ""; // Selected Store object / {}

    // it will set serviceMethod responding deal serviceMethods array
    switch (data?.state?.serviceMethod) {
      case "delivery":
        serviceMethod = "Delivery";
        break;
      case "takeaway":
        serviceMethod = "Carry-Out";
        break;
      case "dinein":
        serviceMethod = "Dine-In";
        break;
      default:
        serviceMethod = "NoError";
        break;
    }

    // set Store
    if (data?.state?.serviceMethod === "delivery") {
      store = data?.state?.storeName;
    } else {
      store = data?.state?.storeNameSelected;
    }

    let currentDay = new Date().toLocaleString("en-us", { weekday: "long" }); //returns the current day name of the week

    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    let d = (new Date() / 1000) * 1000 - 5 * 60 * 60 * 1000;
    const date = new Date(d);
    const dayIndex = date.getDay();

    //  Will retrun Error
    if (!data?.deal?.days?.includes(days[dayIndex])) {
      /* Check if current day exists in deal or not */
      return `Not valid on ${currentDay}`;
    } else if (!data?.deal?.serviceMethods?.includes(serviceMethod)) {
      /* Check if serviceMethod exists in deal or not */
      return data?.state?.serviceMethod !== ""
        ? `Not valid for ${serviceMethod}`
        : serviceMethod;
    } else if (!data?.deal?.stores?.includes(store?._id)) {
      /* Check if Store exists in deal or not */
      return store
        ? `Not available for ${store?.storeName?.en} Store`
        : "Select Store First";
    } else {
      /* if no error in deal */
      return `NoError`;
    }
  } catch (error) {
    console.log(error);
    return;
  }
};

export const setCookingInstruction = (cookingInstruction) => {
  try {
    let match1 = "Double Cut Az Pismis";
    let match2 = "Square Cut Az Pismis";
    let match3 = "Double Cut Cook Pismis";
    let match4 = "Square Cut Cook Pismis";
    let match5 = "Cook Pismis";
    let match6 = "Az Pismis";
    let match7 = "Double Cut";
    let match8 = "Square Cut";

    let cook = "",
      cuts = "",
      cooking = "";
    if (cookingInstruction.startsWith(match1)) {
      cook = "Azpismis";
      cuts = "DoubleCut";
      cooking = cookingInstruction.replace(match1, "");
    } else if (cookingInstruction.startsWith(match2)) {
      cook = "Azpismis";
      cuts = "SquareCut";
      cooking = cookingInstruction.replace(match2, "");
    } else if (cookingInstruction.startsWith(match3)) {
      cook = "Cookpismis";
      cuts = "DoubleCut";
      cooking = cookingInstruction.replace(match3, "");
    } else if (cookingInstruction.startsWith(match4)) {
      cook = "Azpismis";
      cuts = "SquareCut";
      cooking = cookingInstruction.replace(match4, "");
    } else if (cookingInstruction.startsWith(match5)) {
      cook = "Cookpismis";
      cuts = "";
      cooking = cookingInstruction.replace(match5, "");
    } else if (cookingInstruction.startsWith(match6)) {
      cook = "Azpismis";
      cuts = "";
      cooking = cookingInstruction.replace(match6, "");
    } else if (cookingInstruction.startsWith(match7)) {
      cook = "";
      cuts = "DoubleCut";
      cooking = cookingInstruction.replace(match7, "");
    } else if (cookingInstruction.startsWith(match8)) {
      cook = "";
      cuts = "SquareCut";
      cooking = cookingInstruction.replace(match8, "");
    } else {
      cooking = cookingInstruction;
    }
    return {
      cook,
      cuts,
      cooking,
    };
  } catch (error) {
    console.log(error);
  }
};

export const getHeightLightTime = (time) => {
  if (
    moment(new Date().setMinutes(new Date().getMinutes() + time)).format("mm") >
      0 &&
    moment(new Date().setMinutes(new Date().getMinutes() + time)).format("mm") <
      5
  ) {
    return "05";
  } else if (
    moment(new Date().setMinutes(new Date().getMinutes() + time)).format("mm") >
      5 &&
    moment(new Date().setMinutes(new Date().getMinutes() + time)).format("mm") <
      10
  ) {
    return "10";
  } else if (
    moment(new Date().setMinutes(new Date().getMinutes() + time)).format("mm") >
      10 &&
    moment(new Date().setMinutes(new Date().getMinutes() + time)).format("mm") <
      15
  ) {
    return "15";
  } else if (
    moment(new Date().setMinutes(new Date().getMinutes() + time)).format("mm") >
      15 &&
    moment(new Date().setMinutes(new Date().getMinutes() + time)).format("mm") <
      20
  ) {
    return "20";
  } else if (
    moment(new Date().setMinutes(new Date().getMinutes() + time)).format("mm") >
      20 &&
    moment(new Date().setMinutes(new Date().getMinutes() + time)).format("mm") <
      25
  ) {
    return "25";
  } else if (
    moment(new Date().setMinutes(new Date().getMinutes() + time)).format("mm") >
      25 &&
    moment(new Date().setMinutes(new Date().getMinutes() + time)).format("mm") <
      30
  ) {
    return "30";
  } else if (
    moment(new Date().setMinutes(new Date().getMinutes() + time)).format("mm") >
      30 &&
    moment(new Date().setMinutes(new Date().getMinutes() + time)).format("mm") <
      35
  ) {
    return "35";
  } else if (
    moment(new Date().setMinutes(new Date().getMinutes() + time)).format("mm") >
      35 &&
    moment(new Date().setMinutes(new Date().getMinutes() + time)).format("mm") <
      40
  ) {
    return "40";
  } else if (
    moment(new Date().setMinutes(new Date().getMinutes() + time)).format("mm") >
      40 &&
    moment(new Date().setMinutes(new Date().getMinutes() + time)).format("mm") <
      45
  ) {
    return "45";
  } else if (
    moment(new Date().setMinutes(new Date().getMinutes() + time)).format("mm") >
      45 &&
    moment(new Date().setMinutes(new Date().getMinutes() + time)).format("mm") <
      50
  ) {
    return "50";
  } else if (
    moment(new Date().setMinutes(new Date().getMinutes() + time)).format("mm") >
      50 &&
    moment(new Date().setMinutes(new Date().getMinutes() + time)).format("mm") <
      55
  ) {
    return "55";
  } else if (
    moment(new Date().setMinutes(new Date().getMinutes() + time)).format("mm") >
      55 &&
    moment(new Date().setMinutes(new Date().getMinutes() + time)).format("mm") <
      60
  ) {
    return "00";
  } else {
    return "00";
  }
};

// Call Drinks
export const drinkApiCall = async () => {
  const drinkResponse = await apiServices.getMenuByType("drink");

  const drinkData = drinkResponse.data.data;
  let ayran = {};
  let ayranVariants = [];
  let sizeVariants = {};

  // eslint-disable-next-line no-unused-vars
  const variantData = drinkData.filter((item) => {
    if (item.flavorCode === "_AYRAN") {
      const newObj = _.omit(item.variants[0], "sizeName");
      ayran = { ...item };
      sizeVariants = {
        ...sizeVariants,
        _AYRAN: {
          "200 ML": item.variants?.filter(
            (i) => i?.productCode === "_300AYRAN"
          )?.[0],
          "375 ML": item.variants?.filter(
            (i) => i?.productCode === "375MLAYR"
          )?.[0],
        },
      };

      ayranVariants.push({
        ...newObj,
        flavourName: item.name,
      });
    } else if (item.flavorCode === "NAYRAN") {
      const newObj = _.omit(item.variants[0], "sizeName");

      sizeVariants = {
        ...sizeVariants,
        NAYRAN: {
          "200 ML": item.variants?.filter(
            (i) => i?.productCode === "200NAYRAN"
          )?.[0],
          "375 ML": item.variants?.filter(
            (i) => i?.productCode === "355NAYRAN"
          )?.[0],
        },
      };

      ayranVariants.push({
        ...newObj,
        flavourName: item.name,
      });
    }

    return item.flavorCode === "_AYRAN" || item.flavorCode === "NAYRAN";
  });

  const showDrinks = drinkResponse.data.data.filter(
    (item) => item.flavorCode !== "_AYRAN" && item.flavorCode !== "NAYRAN"
  );

  showDrinks.push({
    ...ayran,
    variants: ayranVariants,
    sizeVariants,
  });
  return showDrinks;
};

export const fontImport = () => {
  var exampleFontData = {
    "OpenSans-Regular": { fontDisplay: "swap" },
    "OpenSans-Bold": { fontDisplay: "swap" },
    "OpenSans-SemiBold": { fontDisplay: "swap" },
    "OpenSans-ExtraBold": { fontDisplay: "swap" },
    BabasNeueThin: { fontDisplay: "swap" },
    BabasNeueLight: { fontDisplay: "swap" },
    BabasNeueBold: { fontDisplay: "swap" },
    BabasNeueBook: { fontDisplay: "swap" },
    // Etc.
  };

  var observers = [];

  // Make one observer for each font,
  // by iterating over the data we already have
  Object.keys(exampleFontData).forEach(function (family) {
    var data = exampleFontData[family];
    var obs = new FontFaceObserver(family, data);
    observers.push(obs.load());
  });

  Promise.all(observers)
    .then(function (fonts) {
      fonts.forEach(function (font) {
        document.documentElement.className += "body";
      });
    })
    .catch(function (err) {
      console.warn("Some critical font are not available:", err);
    });
};

export const isFreeDeal = (deal, action) => {
  try {
    const dealCodes = [
      "_AZ1522",
      "_AZ152",
      "_O021",
      "_AZ153",
      /* New Customer Combined */
      "_AZ153_AZ1531",
      /* Late Order Combined*/
      "_AZ1501_AZ1502",
      "_O057_O064",
      "_AZ1502",
      "_AZ1501",
      "_AZ1531",
      "_O057",
      "_O064",
      "_AZ1711",
      "_AZ1712",
      "_AZ1713",
      "_AZ1701",
      "_AZ1702",
      "_AZ1701_AZ1702",
      "_AZ1703",
      "_AZ1704",
      "_AZ1703_AZ1704",
      "_AZ1705",
      "_AZ1706",
    ];

    const dealCodesModify = [
      "_AZ1522",
      "_AZ152",
      "_O021",
      "_AZ153",
      /* New Customer Combined */
      "_AZ153_AZ1531",
      "_AZ1711",
      "_AZ1712",
      "_AZ1713",
    ];

    if (action === "modify" || action === "cancel") {
      return dealCodesModify.some((_deal) => {
        return _deal === (deal || {}).code;
      });
    } else {
      return dealCodes.some((_deal) => {
        return _deal === (deal || {}).code;
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const lateOrderCouponCheck = (deal, couponType) => {
  let newDate = new Date();

  let currentDate = moment(newDate)
    .utcOffset("+04:00")
    .format("DD.MMM.YYYY HH:mm");
  let endDate = moment(deal.endDate)
    .utcOffset("+00:00")
    .format("DD.MMM.YYYY HH:mm");
  let startDate = moment(deal.startDate)
    .utcOffset("+00:00")
    .format("DD.MMM.YYYY HH:mm");

  let time = getTimeRemaining(deal.startDate);

  let time2 = getTimeRemaining(deal.endDate);

  if (deal.redeemed) {
    return { Redeemed: true };
  } else if (new Date(currentDate) > new Date(endDate)) {
    return { Expired: true };
  } else if (
    new Date(currentDate) < new Date(startDate) &&
    ((deal || {}).deal || {}).code === "_AZ083"
  ) {
    return { remaining: true, time };
  } else if (
    new Date(currentDate) < new Date(startDate) &&
    couponType !== "customer"
  ) {
    return { remaining: true, time };
  } else if (
    (time2.days <= 2 && Math.sign(time2.days) === 1) ||
    time2.days === 0
  ) {
    return { get_now: false, nearToExpired: true };
  } else if (!deal.active) {
    return { InActive: true };
  } else {
    return { get_now: false };
  }
};

export const getTimeRemaining = (endtime) => {
  var today = new Date();
  today.setHours(today.getUTCHours() + 4);
  var startDate = new Date(endtime);
  startDate.setHours(startDate.getUTCHours());
  const total = startDate - today;
  const seconds = Math.floor((total / 1000) % 60);
  const minutes = Math.floor(((total % 86400000) % 3600000) / 60000);
  const hours = Math.floor((total % 86400000) / 3600000);
  const days = Math.floor(total / 86400000);

  return {
    total,
    days,
    hours,
    minutes,
    seconds,
  };
};

export const isEmailValid = (email) => {
  if (email) {
    let regex = new RegExp(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/);
    return regex.test(email);
  } else return true;
};
